jQuery(function() {
    FastClick.attach(document.body);
});

jQuery(document).foundation();

jQuery(document).ready(function($) {
  // clear and restore form input values
  var el = $('input[type=text], input[type=email], textarea, input.header-search');

  el.focus(function(e) {
    if (e.target.value === e.target.defaultValue)
      e.target.value = '';
  });

  el.blur(function(e) {
    if (e.target.value === '')
      e.target.value = e.target.defaultValue;
  });

  // Main Menu and Search interaction
  $('.menu-open').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('.main-menu').addClass('active');
    $('body').addClass('active-menu');
  });

  $('.search .a1').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('.search-menu').addClass('active');
    $('body').addClass('active-menu');
    $('.search-box').focus();
  });

  $('.search-menu').click(function(e) {
    e.stopPropagation();
  });


  // Show main menu click event
  $('.search-menu .menu-close').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('.search-menu').removeClass('active');
    $('body').removeClass('active-menu');
  });

  // Close main menu click event
  $('.main-menu .menu-close').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('.main-menu').removeClass('active');
    $('body').removeClass('active-menu');
  });

  // Listen for click events on the document NOT in the menu, and hide menu if it's visible.
  $(document).click(function(event) {
    if(!$(event.target).closest('.main-menu').length && !$(event.target).is('.main-menu')) {
      if($('body').hasClass('active-menu')) {
        $('.main-menu').removeClass('active');
        $('.search-menu').removeClass('active');
        $('body').removeClass('active-menu');
      }
    }
  });

  // Show sub menu items in primary nav
  $('.nav-primary .has_children .a1').click(function(e) {
    e.preventDefault();
    if ($(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active');
    } else {
      $('.nav-primary .has_children.active').removeClass('active');
      $(this).parent().addClass('active');
    }
  });

  $('.ob-header').click(function(e) {
    e.preventDefault();
    $('.online-banking-widget').toggleClass('active');
  });

  /* ONLINE BANKING LOGIN interface. */
  $('.ob-account-type-display').click(function(e) {
    $('.ob-account-type-selector').toggleClass('active');
  });

  $('.ob-account-type-list a').click(function (e) {
    var $acc = $(this);
    var $accName = $acc.text();
    $('.ob-account-type-selector').removeClass('active');
    $('.ob-account-type-display').text($accName);
  });


  // Content Accordions
  $('.accordion-heading').click(function(e){
    e.preventDefault();

    if ($(this).parent().hasClass('expanded')) {
      $(this).parent().removeClass('expanded');
    } else {
      $('.accordion-panel.expanded').removeClass('expanded');
      $(this).parent().addClass('expanded');
    }
  });

  // Content Tabs
  $('ul.tabs li').click(function(){
    var tab_id = $(this).attr('data-tab');

    if (!$(this).hasClass('active')) {
      $('ul.tabs li.active').removeClass('active');
      $('.tab-content.active').removeClass('active');
      $(this).addClass('active');
      $('#'+tab_id).addClass('active');
    }
  });
});
