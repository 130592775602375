jQuery(document).ready(function ($) {
  speedbumpMe();
});

function speedbumpMe() {
  // find all external links
  $('a:not([href=""], [href^="http://www.farmersstate.com"], [href^="https://www.farmersstate.com"], [href^="http://farmersstate.com"], [href^="https://farmersstate.com"], [href*="secureinternetbank.com"], [href*="lrsws.co"], [href*=".local"], [href^="mailto"], [href^="tel"], [href^="/"], [href^="#"], [href^="javascript"], [class*="nospeedbump"], [id*="url-link"], [id*="close-speedbump"], [class*="close-reveal-modal"], [class*="external"])').addClass('external');
  $('a[href^="mailto"]').addClass('mailto');

  // remove external class from links without href attribute
  $('a').each(function (e) {
    if ($(this).attr('href') === undefined) {
      $(this).removeClass('external');
    }
  });

  // add foundation reveal attribute to external links
  $('a.external').attr('data-reveal-id', 'speedbump');
  $('a.mailto').attr('data-reveal-id', 'speedbump2');

  // add the link and title to the modal window
  $(function () {
    $('a.external').click(function () {
      var url = $(this).attr('href');
      var title = $(this).attr('title');
      $('#url_link').attr('href', url);
    });
    $('a.mailto').click(function () {
      var url = $(this).attr('href');
      var title = $(this).attr('title');
      $('#url_link2').attr('href', url);
    });
  });

  // close speedbump when clicking acknowledgment button
  $('.reveal-modal .btn-cancel').click(function (e) {
    e.preventDefault();
    $('.reveal-modal').foundation('reveal', 'close');
  });
}
